@import '../../../../app/style/variables/colors';
@import '../../../../app/style/variables/size';
@import '../../../../app/style/variables/fonts';

p {
  padding: 0;
  margin: 0;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.balanceContainer {
  display: flex;
  padding: 4px 16px;
  border-radius: 50px;
  background-color: var(--color-yellow);
  box-sizing: border-box;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  line-height: 19px;

  &.large {
    padding: 10px;
    min-width: 75px;
    height: 40px;
    font-size: 16px;
    line-height: 20px;
  }

  &.circle {
    padding: 10px;
    min-width: 40px;
    height: 40px;
    font-size: 16px;
    margin-right: 0;
  }
}

.point {
  display: flex;
  margin-right: 10px;

  justify-content: center;
  align-items: center;
  vertical-align: baseline;

  &:last-child {
    margin-right: 0;
  }
}

.balance {
  position: relative;
  font-variant-numeric: lining-nums proportional-nums;
  font-size: inherit !important;
  line-height: inherit !important;
  font-weight: 700 !important;
  letter-spacing: -0.42px;
  text-transform: uppercase;
  margin-right: 5px;
  color: var(--color-white);
  vertical-align: baseline;
  font-family: $font-cambay;
  top: 1px;
}
